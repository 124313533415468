export default [
  {
    path: '/category',
    name: 'categories-management',
    component: () => import('@/views/Categories/Management.vue'),
    meta: {
      title: 'Management'
    }
  },
  {
    path: '/category/add',
    name: 'categories-add',
    component: () => import('@/views/Categories/CategoriesAdd.vue'),
    meta: {
      title: 'Categories Add'
    }
  }
  // {
  //   path: '/category-call',
  //   name: 'categories-call',
  //   component: () => import('@/views/Categories/CallCategories.vue'),
  //   meta: {
  //     title: 'Call Category'
  //   }
  // }
]
