export default [
  {
    path: '/skill-name',
    name: 'skill-name',
    component: () => import('@/views/SkillName/index.vue'),
    meta: {
      title: 'Skill-Name'
    }
  }
]
