export default [
  {
    path: '/contact-relation',
    name: 'contact-relation',
    component: () => import('@/views/ContactRelation/ContactRelation.vue'),
    meta: {
      title: 'Contact Relation'
    }
  },
  {
    path: '/contact-type',
    name: 'contact-type',
    component: () => import('@/views/ContactType/ContactType.vue'),
    meta: {
      title: 'Contact Type'
    }
  },
  {
    path: '/solution-type',
    name: 'solution-type',
    component: () => import('@/views/SolutionType/SolutionType.vue'),
    meta: {
      title: 'Solution Type'
    }
  },
  {
    path: '/special-case',
    name: 'special-case',
    component: () => import('@/views/SpecialCase/SpecialCase.vue'),
    meta: {
      title: 'Special Case'
    }
  },
  {
    path: '/sms-template',
    name: 'sms-template',
    component: () => import('@/views/SmsTemplate/SmsTemplate.vue'),
    meta: {
      title: 'SMS Template'
    }
  },
  {
    path: '/sms-template/add',
    name: 'sms-template-add',
    component: () => import('@/views/SmsTemplate/SmsTemplateAdd.vue'),
    meta: {
      title: 'SMS Template Add'
    }
  },
  {
    path: '/sms-template/detail/:id',
    name: 'sms-template-detail',
    component: () => import('@/views/SmsTemplate/SmsTemplateDetail.vue'),
    meta: {
      title: 'SMS Template Detail'
    }
  },
  {
    path: '/oic-list',
    name: 'oic-list',
    component: () => import('@/views/OicList/OicList.vue'),
    meta: {
      title: 'OIC List'
    }
  },
  {
    path: '/hospital-contact',
    name: 'hospital-contact',
    component: () => import('@/views/HospitalContact/HospoitalContact.vue'),
    meta: {
      title: 'Hospital Contact'
    }
  },
  {
    path: '/court-arbitration',
    name: 'court-arbitration',
    component: () => import('@/views/CourtArbitration/CourtArbitration.vue'),
    meta: {
      title: 'Court Arbitration'
    }
  },
  {
    path: '/form-template',
    name: 'form-template',
    component: () => import('@/views/FormTemplate/FormTemplate.vue'),
    meta: {
      title: 'Form Template'
    }
  },
  {
    path: '/form-template/add',
    name: 'form-template-add',
    component: () => import('@/views/FormTemplate/FormTemplateAdd.vue'),
    meta: {
      title: 'Form Template Add'
    }
  },
  {
    path: '/form-template/detail/:id',
    name: 'form-template-detail',
    component: () => import('@/views/FormTemplate/FormTemplateDetail.vue'),
    meta: {
      title: 'Form Template Detail'
    }
  }
]
