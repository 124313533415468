export default [
  {
    path: '/ticket/contact',
    name: 'ticket-contact',
    component: () => import('@/views/Tickets/Contact.vue'),
    meta: {
      title: 'Contact'
    }
  },
  {
    path: '/ticket/type',
    name: 'ticket-type',
    component: () => import('@/views/Tickets/Type.vue'),
    meta: {
      title: 'Type'
    }
  },
  {
    path: '/plant',
    name: 'plant',
    component: () => import('@/views/Tickets/Plant.vue'),
    meta: {
      title: 'Plant'
    }
  }
]
