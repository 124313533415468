import roleEnum from '@/common/role.enum'
import AuthProvider from '@/resources/AuthProvider'

const AuthService = new AuthProvider()

const state = {
  accessToken: '',
  user: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    userGroupId: ''
  }
}

const getters = {
  user: (state) => state.user,
  accessToken: (state) => state.accessToken
}

const mutations = {
  SET_USER (state, payload) {
    state.accessToken = payload.token
    state.user.id = payload.id
    state.user.firstName = payload.firstName
    state.user.lastName = payload.lastName
    state.user.email = payload.email
    state.user.role = payload.role
    state.user.userGroupId = payload.groupService
  },

  RESET_USER (state) {
    state.accessToken = ''
    state.user.id = ''
    state.user.firstName = ''
    state.user.lastName = ''
    state.user.email = ''
    state.user.role = ''
    state.user.userGroupId = ''
  }
}

const actions = {
  async signIn ({ dispatch }, payload) {
    const { data } = await AuthService.login({ username: payload.username, password: payload.password })
    const user = { ...data.user }
    user.ability = [{ action: 'manage', subject: 'all' }]
    localStorage.setItem('userData', JSON.stringify(user))
    if (data.user.role !== roleEnum.SUPER_ADMIN) throw new Error('Unauthorized')
    if (data.token) {
      dispatch('setUser', {
        ...data.user,
        token: data.token
      })
    }
  },

  resetUser ({ commit }) {
    commit('RESET_USER')
  },

  setUser ({ commit }, payload) {
    commit('SET_USER', payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
