// eslint-disable-next-line arrow-body-style
import store from '@/store'

export const getAccessToken = () => store.getters['user/accessToken']

export const getAuthToken = () => {
  let token = {}
  const accessToken = getAccessToken()
  if (accessToken) {
    token = {
      key: 'Authorization',
      value: `Bearer ${accessToken}`
    }
  }
  return token
}
export const isUserLoggedIn = () => localStorage.getItem('userData') && true
// && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === 'super admin') return '/'
  return { name: 'login' }
}
