export default [
  {
    path: '/email-template',
    name: 'email-template',
    component: () => import('@/views/EmailSetting/EmailTemplate/EmailTemplate.vue'),
    meta: {
      title: 'Email Template'
    }
  },
  {
    path: '/email-template/add',
    name: 'email-template-add',
    component: () => import('@/views/EmailSetting/EmailTemplate/EmailTemplateAdd.vue'),
    meta: {
      title: 'Email Template Add'
    }
  },
  {
    path: '/email-template/detail/:id',
    name: 'email-template-detail',
    component: () => import('@/views/EmailSetting/EmailTemplate/EmailTemplateDetail.vue'),
    meta: {
      title: 'Email Template Detail'
    }
  },
  {
    path: '/email-group',
    name: 'email-group',
    component: () => import('@/views/EmailSetting/EmailGroup/EmailGroup.vue'),
    meta: {
      title: 'Email Group'
    }
  }
]
