export default [
  {
    path: '/customer-group',
    name: 'customer-group',
    component: () => import('@/views/Customers/Group.vue'),
    meta: {
      title: 'Group'
    }
  },
  {
    path: '/customer-type',
    name: 'customer-type',
    component: () => import('@/views/Customers/Type.vue'),
    meta: {
      title: 'Type'
    }
  },
  {
    path: '/company-center',
    name: 'company-center',
    component: () => import('@/views/Customers/CompanyCenter.vue'),
    meta: {
      title: 'CompanyCenter'
    }
  },
  {
    path: '/company-group',
    name: 'company-group',
    component: () => import('@/views/Customers/CompanyGroup.vue'),
    meta: {
      title: 'CompanyGroup'
    }
  },
  {
    path: '/company-department',
    name: 'company-department',
    component: () => import('@/views/Customers/CompanyDepartment.vue'),
    meta: {
      title: 'CompanyDepartment'
    }
  }
]
