import Vue from 'vue'
import VueRouter from 'vue-router'
// Routes
import { getAccessToken } from '@/auth/utils'
import users from './routes/users'
import categories from './routes/categories'
import customers from './routes/customers'
import tickets from './routes/tickets'
import others from './routes/others'
import skillName from './routes/skill-name'
import newRoute from './routes/new'
import emailSetting from './routes/email-setting'
import knowledgeType from './routes/knowledge-type'
import knowledgeCategoriesMapping from './routes/knowledge-categories-mapping'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: '/user-group'
    },
    ...users,
    ...categories,
    ...customers,
    ...tickets,
    ...others,
    ...skillName,
    ...newRoute,
    ...emailSetting,
    ...knowledgeType,
    ...knowledgeCategoriesMapping,
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true
          }
        ]
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true
      }
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
})

const publicRoute = ['login']
// const superAdminRoute = ['ClientList', 'AdminList', 'AdminAdd']
// const nonSuperAdminRoute = ['ReportHome']

router.beforeEach((to, from, next) => {
  const routeName = to.name

  // const role = getRole()

  if (publicRoute.some((pr) => pr === routeName)) {
    next()
  } else {
    const token = getAccessToken()

    if (!token) {
      next({ name: 'login' })
    } else {
      next()
    }
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
